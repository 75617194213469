@import '../../styles/mixins/_schoolheading'

.schooldisheschapter
  color: #424241
  padding-top: 1rem

  &__content
    padding-left: 0
    padding-right: 0

  &__logo
    height: auto
    flex: 0
    padding: 0

  &__dishes
    min-height: 22rem
    gap: 1rem

  &__dish-container
    background-color: #dceeeb
    padding: 1rem
    min-height: 12rem

  &__badge
    padding: 0.5rem 1.4rem
    border-radius: 0.7rem

    &__vegan
      background-color: #88ac3f 

    &__side
      background-color: #575e95

    &__dessert
      background-color: #f5b843
    
    &_container
      height: 2.5rem
  
  &__main-dish
    height: 7.1875rem

  h1
    @include school-heading

  h2
    font-size: 2.3vw
    font-size: clamp(0.5rem,2.3vw,10rem)

  &__healthy-benefit
    &_img
      width: 8rem

  &__footer
    &_icon 
      width: 25px

  &_h1
    font-size: 4.375rem // TODO: Unused, remove it maybe
    @include media-breakpoint-down(md)
      font-size: 3rem !important
    @include media-breakpoint-down(sm)
      font-size: 2rem !important

  &_h2
    font-size: 3.438rem !important
    @include media-breakpoint-down(md)
      font-size: 2.5rem !important
    @include media-breakpoint-down(sm)
      font-size: 1.6rem !important

  &_h3
    font-size: 2.5rem !important
    @include media-breakpoint-down(md)
      font-size: 2rem !important
    @include media-breakpoint-down(sm)
      font-size: 1.2rem !important

  &_h4
    font-size: 2.188rem !important
    @include media-breakpoint-down(md)
      font-size: 1.5rem !important
    @include media-breakpoint-down(sm)
      font-size: 1rem !important

  &_h5
    font-size: 1.875rem !important
    @include media-breakpoint-down(md)
      font-size: 1.25rem !important
    @include media-breakpoint-down(sm)
      font-size: 0.8rem !important

  &_h6
    font-size: 1.563rem !important
    @include media-breakpoint-down(md)
      font-size: 1rem !important
    @include media-breakpoint-down(sm)
      font-size: 0.6rem !important